/* From Uiverse.io by Yaya12085 */ 
.form-container {
    width: 320px;
    border-radius: 0.75rem;
    background-color: rgba(17, 24, 39, 1);
    padding: 2rem;
    color: rgba(243, 244, 246, 1);
  }
  
  .title {
    text-align: center;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
  }
  
  .form {
    margin-top: 1.5rem;
  }
  
  .input-group {
    margin-top: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  
  .input-group label {
    display: block;
    color: rgba(156, 163, 175, 1);
    margin-bottom: 4px;
  }
  
  .input-group input {
    width: 100%;
    border-radius: 0.375rem;
    border: 1px solid rgba(55, 65, 81, 1);
    outline: 0;
    background-color: rgba(17, 24, 39, 1);
    padding: 0.75rem 1rem;
    color: rgba(243, 244, 246, 1);
  }
  
  .input-group input:focus {
    border-color: rgba(167, 139, 250);
  }
  
  .forgot {
    display: flex;
    justify-content: flex-end;
    font-size: 0.75rem;
    line-height: 1rem;
    color: rgba(156, 163, 175,1);
    margin: 8px 0 14px 0;
  }
  
  .forgot a,.signup a {
    color: rgba(243, 244, 246, 1);
    text-decoration: none;
    font-size: 14px;
  }
  
  .forgot a:hover, .signup a:hover {
    text-decoration: underline rgba(167, 139, 250, 1);
  }
  
  .sign {
    display: block;
    width: 100%;
    background-color: rgba(167, 139, 250, 1);
    padding: 0.75rem;
    text-align: center;
    color: rgba(17, 24, 39, 1);
    border: none;
    border-radius: 0.375rem;
    font-weight: 600;
  }

  #contenedor{
    align-self: start;
  }
  .botonAdmin {    
    width: 100%;
    max-width: 100px;
    padding: 0.75rem;
    text-align: center;
    color: rgba(17, 24, 39, 1);
    border: none;
    border-radius: 0.375rem;
    font-weight: bold;
  }
  .botonAdminGrande{
    max-width: 200px;
  }
  #contenedor>header>.botonAdmin{
    color: white;
  }
  .botonAdminSmall{
    width: 50px;
    height: 50px;
  }
  .adminArticle{
    background-color: rgba(17, 24, 39, 1);
    border: 1px solid rgba(55, 65, 81, 1);
  }
  .adminArticle input, .adminArticle select{
    background-color: rgba(17, 24, 39, 1);
    border: 1px solid rgba(55, 65, 81, 1);
  }
  article .editArea{
    background-color: rgba(37, 48, 70, 0.3);
  }
  article .editArea .campos{
    position: relative;
    min-height: 280px;
  }