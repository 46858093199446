@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --dark-background: #020202;
  --dark-foreground: #F8F8FC;
  --light-foreground: #020202;
  --light-background: #F8F8FC;
}
html, body, #root{
  width: 100%;
  height: 100%;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}
#root{
  display: flex;
  justify-content: center;
  align-items: center;
}
body {
  background-color: var(--dark-background);
  color: var(--dark-foreground);
}
.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.root-light-mode{
  transition: all 1s;
  background-color: var(--light-background);
  color: var(--light-foreground);
}

.homeLink{
  text-decoration: none;
  text-transform: uppercase;
  font-size: 3rem;
}
.homeLink:hover{
  transition: all 0.5s;
  opacity: 0.8;
}