@tailwind base;
@tailwind components;
@tailwind utilities;
:root{
    --gris-medio:'888888';
    --altura-header:80px;
}

#main-logo{
    width: 48px;
    position: relative;
    top: -7px;
}
#presentacion{
    font-family: "Merriweather", system-ui;
    font-weight: 300;
    font-style: normal;
}
#presentacion h2, .destacado{
    font-weight: 300;
    font-style: normal;
}
#presentacion span, .superDestacado{
    font-weight: 700;
    font-style: italic;
}
#homeNav{
    position: relative;
    top: 300px;
    opacity: 0;
    transition: all 1s;
}
#skills svg{
    opacity: 0;
    transition: opacity 0.5s;
}
.categoria{
    position: relative;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.categoria>header, .categoria>footer{
    height: 60px;
}
.categoria>footer{
    align-items: center;
}
.categoria>main{
    position: relative;
    max-height: calc(100vh - 120px);
    overflow-y: auto;
}
.imgFadeIn{
    transition: opacity 0.3s ease-in-out;
}

.hoverFade {
    @apply hover:opacity-50 transition-opacity duration-300 ;
  }

.contenido-container{
    background-color: rgba(255,255,255, 0.06);
    max-height: calc(100% - 120px);
}

.proyecto{
    max-width: 90%;
    grid-template-columns: 10% 45% 25% auto;
}
.proyecto>.titulo{
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
}
.proyecto>.descripcion span{
    display: block;
    margin: 10px 0;
    font-size: 10px;
    opacity: 0.5;
}

.btn-estandar{
    background-color: #888888!important;
    color: black!important;
}

@media screen and (max-width:640px){
    .proyecto {
      grid-template-columns: 1fr;
      padding-bottom: 1em;
    }
    .proyecto>.titulo{
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    .contenido-container{
        background-color: transparent;
    }
}

@media screen and (min-width:640px){
 .categoria>main{
        height: calc(100vh - 120px);
        display: flex;
        align-items: center;
        max-width: 100vw;
        overflow-x: hidden;
        min-width: 85%;
        justify-content: center;
    }
    .categoria .contenido-container>.proyecto{
        max-width: 90%;
        grid-template-columns: 20% 40% 0 30%;
    }
}
@media screen and (min-width:1100px){
    .categoria .contenido-container>.proyecto{
        max-width: 90%;
        grid-template-columns: 10% 40% 30% auto;
    }
}

@media screen and (min-width:1200px) {
    #homeNav{
        flex-direction: row;
        gap: 1rem;
    }
    .homeLink{
        font-size: 4rem;
    }  
}

@media screen and (min-width:1500px) {
    #homeNav{
        gap: 2rem;
    }
    .homeLink{
        font-size: 5rem;
    }  
}